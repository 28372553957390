import { createAsyncRequestTypes } from 'utils'

export const DDI_FORM = '@@ddiForm/'
export const DEFAULT_VALUE_TYPES = {
  DATA_SELECTOR: 'DATA_SELECTOR',
  VALUE: 'VALUE'
}
export const ENTER_NEW_MODE = `${DDI_FORM}ENTER_NEW_MODE`
export const CLOSE_DROP_DOWN = `${DDI_FORM}CLOSE_DROP_DOWN`
export const ADD_BLANK_ROW = `${DDI_FORM}ADD_BLANK_ROW`
export const SET_SELECTED_CELL = `${DDI_FORM}SET_SELECTED_CELL`
export const SET_SELECTED_ROW_INDEX = `${DDI_FORM}SET_SELECTED_ROW_INDEX`
export const BEFORE_DESTROY = `${DDI_FORM}BEFORE_DESTROY`
export const BLUR = `${DDI_FORM}BLUR`
export const CANCEL = `${DDI_FORM}CANCEL`
export const CANCEL_COMPLETE = `${DDI_FORM}CANCEL_COMPLETE`
export const CHANGE = `${DDI_FORM}CHANGE`
export const CHANGE_FORM_TAB = `${DDI_FORM}CHANGE_FORM_TAB`
export const TRY_CHANGE_FORM_TAB = `${DDI_FORM}TRY_CHANGE_FORM_TAB`
export const CLOSE_SEARCH = `${DDI_FORM}CLOSE_SEARCH`
export const CUSTOM_ACTION = `${DDI_FORM}CUSTOM_ACTION`
export const DELETE_ENTITY = `${DDI_FORM}DELETE_ENTITY`
export const DISABLE_FIELDS = `${DDI_FORM}DISABLE_FIELDS`
export const DESTROY = `${DDI_FORM}DESTROY`
export const EDIT_GRID_CELL = `${DDI_FORM}EDIT_GRID_CELL`
export const ENABLE_FIELDS = `${DDI_FORM}ENABLE_FIELDS`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${DDI_FORM}EXACT_MATCH_SEARCH`
)
export const EXIT = `${DDI_FORM}EXIT`
export const CLEAR_CHILD_INDEX = `${DDI_FORM}CLEAR_CHILD_INDEX`
export const FOCUS = `${DDI_FORM}FOCUS`
export const GET_ENTITY = createAsyncRequestTypes(`${DDI_FORM}GET_ENTITY`)
export const GET_ENTITY_SUCCESS_COMPLETE = `${DDI_FORM}GET_ENTITY_SUCCESS_COMPLETE`
export const UNLOCK_ENTITY = createAsyncRequestTypes(`${DDI_FORM}UNLOCK_ENTITY`)
export const INITIALIZE = `${DDI_FORM}INITIALIZE`
export const PRE_INITIALIZE = `${DDI_FORM}PRE_INITIALIZE`
export const PARTIAL_MATCH_SEARCH = createAsyncRequestTypes(
  `${DDI_FORM}PARTIAL_MATCH_SEARCH`
)
export const OPEN_SCREEN = createAsyncRequestTypes(`${DDI_FORM}OPEN_SCREEN`)
export const PROPERTY_CHANGE = createAsyncRequestTypes(
  `${DDI_FORM}@@PROPERTY_CHANGE`
)
// export const PROPERTY_CHANGE_SYMBOL = Symbol('PROPERTY_CHANGE')  unused
export const REGISTER_FIELD = `${DDI_FORM}REGISTER_FIELD`
export const SAVE = createAsyncRequestTypes(`${DDI_FORM}SAVE`)
export const SET_FIELD = `${DDI_FORM}SET_FIELD`
export const SET_FIELDS = `${DDI_FORM}SET_FIELDS`
export const SET_VALUE = `${DDI_FORM}SET_VALUE`
export const SET_VALUES = `${DDI_FORM}SET_VALUES`
export const REGISTER_SUBFORM = `${DDI_FORM}REGISTER_SUBFORM`
export const UPDATE_FORM_TITLE = `${DDI_FORM}UPDATE_FORM_TITLE`
export const UPDATE_PREVIOUS_ORDERS = `${DDI_FORM}UPDATE_PREVIOUS_ORDERS`
export const INDEX_SEARCH_TYPES = {
  AP_TERMS: 'apTerms',
  AR_AGING_LETTERS: 'arAgingLetters',
  AR_TERMS: 'arTerms',
  BANK: 'bank',
  BINS: 'bins',
  BRANCH: 'branch',
  BUY_LINE: 'buyLine',
  CONTACTS: 'contacts',
  CONTAINER: 'container',
  CURRENCY_CODE: 'currencyCode',
  CUSTOMER: 'customer',
  CUSTOMER_SOURCE: 'customerSource',
  CUSTOM_CUBE: 'customCube',
  CUST_CATEGORY: 'custCategory',
  CYCLE_GROUP: 'cycleGroup',
  DIVISION: 'division',
  DOT_CODE: 'dotCode',
  ECOMMERCE_BRANCH: 'eCommerceBranch',
  EVENT_TYPE: 'eventType',
  FINANCIAL_REPORT: 'financialReport',
  GL_ACCOUNT: 'glAccount',
  GL_GROUP: 'glGroup',
  INVOICE_ORDER: 'invoiceOrder',
  JOB: 'job',
  LOT: 'lot',
  MAJOR_BUY_LINE: 'majorBuyLine',
  MAJOR_GROUP: 'majorGroup',
  MARGIN_CAP: 'marginCap',
  MISC_CHARGE: 'miscCharge',
  MISC_CHARGE_PROGRAM: 'miscChargeProgram',
  NONE: 'none',
  NON_VENDOR: 'nonVendor',
  OPTIONS_AND_ACCESSORIES_GROUPS: 'optionsAndAccessoriesGroups',
  ORDER_TYPE: 'orderType',
  PICKER_PACKER: 'pickerPacker',
  PRICE_CONTRACT: 'priceContract',
  PRICE_GROUP: 'priceGroup',
  PRINTERS: 'printers',
  PRODUCT: 'product',
  PRODUCT_LABELS: 'productLabels',
  PRODUCT_LINE: 'productLine',
  PRODUCT_TAX_GROUP: 'productTaxGroup',
  PURCHASE_ORDER: 'purchaseOrder',
  PURCHASE_ORDER_SPECIAL_INSTRUCTIONS: 'purchaseOrderSpecialInstructions',
  REASON_INV_ADJ: 'reasonInvAdj',
  REPORT_WRITER: 'reportWriter',
  SALESMAN: 'salesman',
  SALES_ORDER: 'salesOrder',
  SALES_ORDER_REPORT: 'salesOrderReport',
  SALES_ORDER_SPECIAL_INSTRUCTIONS: 'salesOrderSpecialInstructions',
  SHIP_TO: 'shipTo',
  SHIP_VIA: 'shipVia', // shipVia
  SYSTEM_USERS: 'systemUsers',
  TAX: 'tax',
  TERRITORY: 'territory',
  TRUCK: 'truck',
  UOM: 'uom',
  VENDOR: 'vendor',
  VENDOR_CATEGORY: 'vendorCategory',
  VENDOR_SOURCE: 'vendorSource',
  WAREHOUSE: 'warehouse',
  WARRANTY_FAIL_CODE: 'warrantyFailCode',
  WEB_CAT: 'webCat',
  WHSE_BUY_ZONE: 'whseBuyZone'
}

const MASTER_SCREEN = `${DDI_FORM}@@MASTER_SCREEN@@/`

export const CHANGE_PRIMARY_TAB = `${DDI_FORM}CHANGE_PRIMARY_TAB`
export const CHANGE_SECONDARY_TAB = `${DDI_FORM}CHANGE_SECONDARY_TAB`
export const CHANGE_DISPLAY_TABS = `${DDI_FORM}CHANGE_DISPLAY_TABS`
export const ADD_SCREEN_IN_MODAL = `${DDI_FORM}ADD_SCREEN_IN_MODAL`
export const SET_FORM_META = `${DDI_FORM}SET_FORM_META`
export const SHOW_QUICK_ENTITY = `${DDI_FORM}SHOW_QUICK_ENTITY`
export const DESTROY_FIELD = `${DDI_FORM}DESTROY_FIELD`
export const QUICK_CANCEL_EDIT = `${DDI_FORM}QUICK_CANCEL_EDIT`
