import invariant from 'invariant'
import { is } from 'utils'
/* eslint-disable import/prefer-default-export */

export function bindBehaviors(context, behaviors) {
  Object.keys(behaviors).forEach(behavior => {
    invariant(is.fn(behaviors[behavior]), 'Behaviors must be a function')
    Object.assign(context, { [behavior]: behaviors[behavior].bind(context) })
  })
  if (context.props && context.props.form) {
    context.form = context.props.form
  }
}

const menuTabs = ['filterMenuTab']
export function leftAligncolumns(col) {
  const ret = {
    editable: col.canEdit,
    field: col.propertyName,
    headerName: col.columnHeader,
    menuTabs,
    // suppressSorting: !col.canSort
    sortable: !!col.canSort
    // type: col.align && col.align === 'right' ? 'numberColumn' : undefined
  }

    // && col.align === 'right'/
    let align
    if(col.leftAligned){
      align = 'left';
    }
    else{
      align = 'right';
    }
    ret.cellStyle = {
      'text-align': align
    }
    ret.headerClass = `align-${align}`
    ret.cellClass = `align-${align}`
  
  return ret
}